<template>
  
      <!--====== APPIE SERVICES PART START ======-->
    
    <section class="appie-service-area appie-service-3-area pt-5 pb-100" id="service">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="appie-section-title text-center">
                        <h2 class="appie-title">Somos expertos en la búsqueda de inmuebles. <br>
                            Nuestra plataforma te permite encontrar al dueño de un terreno en un proceso rápido, simple y garantizado.
                        </h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="200ms">
                        <div class="d-flex justify-content-center">
                            <div class="icon d-flex justify-content-center align-items-center">
                                <div class="material-symbols-outlined mb-0 text-white">
                                shopping_cart
                                </div>
                            </div>
                        </div>
                        <h4 class="appie-title">¿Te interesa comprar un inmueble?</h4>
                        <p>Utiliza nuestra plataforma para encontrar de inmediato al dueño del inmueble que pretendes comprar. </p>
                        <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 item-2 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="400ms">
                        <div class="d-flex justify-content-center">
                            <div class="icon d-flex justify-content-center align-items-center">
                                <div class="material-symbols-outlined mb-0 text-white">
                                    monitoring
                                </div>
                            </div>
                        </div>
                        <h4 class="appie-title">Información de un Terreno Valioso</h4>
                        <p>Hoy en día, la información es muy valiosa. Nosotros te proporcionamos con los datos del inmueble que te interesa.</p>
                        <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 item-3 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="600ms">
                        <div class="d-flex justify-content-center">
                            <div class="icon d-flex justify-content-center align-items-center">
                                <div class="material-symbols-outlined mb-0 text-white">
                                query_stats
                                </div>
                            </div>
                        </div>
                        <h4 class="appie-title">Datos Precisos</h4>
                        <p>Nuestro equipo de profesionales se encarga de investigar de una manera exhaustiva la información del dueño de un terreno o construcción.</p>
                        <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
                    </div>
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="appie-single-service appie-single-services-3 text-center mt-30 item-4 wow animated fadeInUp" data-wow-duration="2000ms" data-wow-delay="800ms">
                        <div class="d-flex justify-content-center">
                            <div class="icon d-flex justify-content-center align-items-center">
                                <div class="material-symbols-outlined mb-0 text-white">
                                verified
                                </div>
                            </div>
                        </div>
                        <h4 class="appie-title">Proceso Legítimo</h4>
                        <p>La información obtenida se basa en los datos recabados en los registros públicos correspondientes.</p>
                        <!-- <a href="#">Read More <i class="fal fa-arrow-right"></i></a> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE SERVICES PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>