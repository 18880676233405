<template>
  <div>

    <!--
    <div class="loader-wrap">
        <div class="preloader"><div class="preloader-close">Preloader Close</div></div>
        <div class="layer layer-one"><span class="overlay"></span></div>
        <div class="layer layer-two"><span class="overlay"></span></div>
        <div class="layer layer-three"><span class="overlay"></span></div>
    </div>
    -->
    <div class="row g-0">
      <div class="col-12 col-md-8  col-lg-7 col-xl-6 content-area">
          <div class="h-100 p-4 d-flex justify-content-between flex-column align-items-center">
            <div v-if="step === 0" class="d-flex justify-content-between flex-column align-items-center">
              <div class="step0">
                <div class="text-center mt-5 d-flex justify-content-center">
                  <img src="../../assets/images/logo_quien.png" alt="" width="300">
                </div>
                <div class="row mt-5">
                  <div v-if="!address" class="col-12 step0">
                    <input type="text" v-model="searchQuery" class="form-control form-control-lg" placeholder="Dirección">
                    <p class="text-white my-2 text-center">
                      Ingresa una dirección, colonia, zona
                      o municipio donde se encuentra el
                      inmueble que buscas.
                    </p>
                  </div>

                  <div v-if="!address" class="col-12">
                    <div v-for="(address, n) in addressResults.features"
                      class="result" :key="`address-${n}`"
                      @click="selectAddressAndCenterMap(address)"
                    >
                      {{ address.place_name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-white" v-if="address">
                    <div class="h3">
                      {{ address.place_name }}
                    </div>

                    <div class="info d-flex justify-content-center align-items-center mt-5">
                      <img src="../../assets/images/tutorial1.gif" class="rounded me-5 mb-5" height="200" width="200">
                      <div>
                        <ol class="instructions">
                          <li>Localiza en el mapa el terreno o construcción que buscas.</li>
                          <li>Da click a la herramienta de polígono. <span class="material-symbols-outlined">polyline</span></li>
                          <li>Crea un polígono naranja sobre la totalidad del terreno o construcción que buscas.</li>
                          <li>Da un click para generar un punto de quiebre en el polígono. <br>
                            Da un doble click para cerrar el polígono.</li>
                          <li>Para eliminar el polígono creado y hacer uno nuevo, da click a la herramienta de desecho. <span class="material-symbols-outlined">delete</span></li>
                        </ol>
                        <div class="d-flex justify-content-end">
                          <i class="d-none d-md-block fas fa-arrow-right fa-2x mt-2"></i>
                          <i class="d-block d-md-none fas fa-arrow-down fa-2x mt-2"></i>
                        </div>
                      </div>
                    </div>
                    <div v-if="polygonx != null" class="mt-2 cursor-pointer text-white text-decoration-underline" @click="step = 1">
                      Siguente
                      <i class="fas fa-arrow-right ms-2"></i>
                    </div>
                  </div>
            </div>
            <div v-if="step === 1" class="form-step-1">
              <div class="text-center mt-5">
                <img src="../../assets/images/logo_quien.png" alt="" width="300">
              </div>
              <form  @submit.prevent="step = 2" class="row g-3">
                <div class="text-white font-weight-bold mt-4 col-12">
                  Ingresa tus datos para continuar:
                </div>
                <div class="col-12">
                  <input required type="text" v-model="user.name" class="form-control form-control-lg" placeholder="Nombre">
                </div>
                <div class="col-12">
                  <input required type="text" v-model="user.email" class="form-control form-control-lg" placeholder="Email">
                </div>
                <div class="col-12">
                  <input required type="tel" v-model="user.phone" class="form-control form-control-lg" placeholder="Teléfono">
                </div>
                <div class="col-12">
                  <button
                    type="submit"
                    class="btn btn-primary w-100 btn-lg mt-4"
                    :disabled="!user.name || !user.email || !user.phone"
                  >
                    Siguiente
                  </button>
                  <div class="pt-2 cursor-pointer text-white text-decoration-underline" @click="step = 0">
                    <i class="fas fa-arrow-left me-2"></i> regresar
                  </div>
                </div>
              </form>
            </div>
            <div v-if="step === 2" class="" >
              <div class="text-center mt-5">
                <img src="../../assets/images/logo_quien.png" alt="" width="300">
              </div>
              <div class="text-white font-weight-bold mb-4 text-center">
                Elige el servicio que deseas:
              </div>
              <div class="row">
                <div class="col-4 offset-2">
                  <div class="plan-card p-3 h-100"  :class="{ 'plan-active': selectedPlan === 'estandar' }" @click="selectedPlan = 'estandar'">
                    <div class="text-center">
                      Búsqueda <br>
                      <b>
                        Estándar
                      </b>
                    </div>
                    <div class="price text-center my-4">
                      $3,500<sup class="ms-2">MXN</sup>
                    </div>
                    <ul>
                      <li>Te decimos quién es el dueño en dos a cinco días hábiles.</li>
                      <li>Envío de reporte informativo a su correo electrónico</li>
                    </ul>
                  </div>
                </div>
                <div class="col-4">
                  <div class="plan-card p-3 h-100 recomended" :class="{ 'plan-active': selectedPlan === 'express' }" @click="selectedPlan = 'express'">
                    <div class="ribbon">
                      Recomendado
                    </div>
                    <div class="text-center">
                      Búsqueda <br>
                      <b>
                        Express
                      </b>
                    </div>
                    <div class="price text-center my-4">
                      $4,500<sup class="ms-2">MXN</sup>
                    </div>
                    <ul>
                      <li>Te decimos quién es el dueño en <b>dos días hábiles.</b></li>
                      <li>Envío de reporte informativo a su correo electrónico</li>
                      <li>Proporcionamos información adicional del dueño, en caso de que la exista</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button :disabled="selectedPlan == null" @click="completeForm()" class="btn btn-primary  btn-lg mt-4">Buscar al Dueño</button>
                <div class="pt-2 cursor-pointer text-white text-decoration-underline" @click="step = 1">
                  <i class="fas fa-arrow-left me-2"></i> regresar
                </div>
              </div>
            </div>

            <div v-if="step === 3" class="text-center" >
              <div class="text-center mt-5">
                <img src="../../assets/images/logo_quien.png" alt="" width="300">
              </div>
              <div class="h2 text-white mt-5">
                Gracias por confiar en nosotros
              </div>
              <div class="text-white h3 mt-4">
                ¡Ya estamos buscando al dueño del terreno!
              </div>
              <p class="text-white mt-3">
                Te hemos enviado un correo electrónico con la información de tu
                solicitud y los pasos para hacer tu pago.
              </p>
              <i class="text-white text-italic mt-3">Nota: Si no encuentras nuestro correo, revisa tu carpeta de “No Deseado” o “Spam”.</i>
              <div class="mt-3">
                <button @click="resetForm()" type="submit" class="btn btn-primary btn-lg mt-4">Realiza una nueva búsqueda</button>
              </div>
            </div>

          </div>
      </div>
      <div class="col-12 col-md-4 col-lg-5 col-xl-6">
        <div id="map">
        </div>
      </div>
    </div>

    <services-home-three />

    <!-- <div class="container text-center">
      <div class="row">
        <div class="col">
          <img src="@/assets/images/about-page-thumb.jpg" alt="" class="w-100">
        </div>
      </div>
    </div> -->



    <project-home-three />

    <footer-home-three />

    <div class="back-to-top back-to-top-3">
      <a href="#"><i class="fal fa-arrow-up"></i></a>
    </div>
    <!--====== APPIE BACK TO TOP PART ENDS ======-->
  </div>
</template>

<script>
import FooterHomeThree from "./FooterHomeThree.vue";
import ProjectHomeThree from "./ProjectHomeThree.vue";
import ServicesHomeThree from "./ServicesHomeThree.vue";
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import _ from 'lodash';


mapboxgl.accessToken = 'pk.eyJ1IjoibHNncnJkIiwiYSI6ImNsOHE4M3U2NDAwejgzbm9nM3M2YnZtMzkifQ.AodbZR_ka2PLb9-ezuYUHQ';

const firebaseConfig = {
  apiKey: "AIzaSyDgXFzOgSwWmulpQLjTMs0mFe9QEqs3Iz4",
  authDomain: "quieneseld.firebaseapp.com",
  projectId: "quieneseld",
  storageBucket: "quieneseld.appspot.com",
  messagingSenderId: "933880676258",
  appId: "1:933880676258:web:0678886c731c75dec4bcfb",
  measurementId: "G-QE6WSE844S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const polygonStyles = [
{
    id: "gl-draw-line",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round"
    },
    paint: {
      "line-color": "#e28533",
      "line-dasharray": [0.2, 2],
      "line-width": 2
    }
  },
  {
    id: "gl-draw-polygon-fill",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    paint: {
      "fill-color": "#e28533",
      "fill-outline-color": "#e28533",
      "fill-opacity": 0.5
    }
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'midpoint']
    ],
    'paint': {
      'circle-radius': 3,
      'circle-color': '#e28533'
    },
  },
  {
    "id": "gl-draw-polygon-stroke-active",
    "type": "line",
    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#9c5415",
      "line-dasharray": [0.2, 2],
      "line-width": 3
    }
  },
  // vertex point halos
  {
    "id": "gl-draw-polygon-and-line-vertex-halo-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    "paint": {
      "circle-radius": 12,
      "circle-color": "#e28533"
    }
  },
  // vertex points
  {
    "id": "gl-draw-polygon-and-line-vertex-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    "paint": {
      "circle-radius": 8,
      "circle-color": "#fff",
    }
  },

  // INACTIVE (static, already drawn)
  // line stroke
  {
      "id": "gl-draw-line-static",
      "type": "line",
      "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
      "layout": {
        "line-cap": "round",
        "line-join": "round"
      },
      "paint": {
        "line-color": "#000",
        "line-width": 3
      }
  },
  // polygon fill
  {
    "id": "gl-draw-polygon-fill-static",
    "type": "fill",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    "paint": {
      "fill-color": "#000",
      "fill-outline-color": "#000",
      "fill-opacity": 0.1
    }
  },
  // polygon outline
  {
    "id": "gl-draw-polygon-stroke-static",
    "type": "line",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": "#000",
      "line-width": 3
    }
  }
]

let map;
let draw;

export default {
  components: {
    ServicesHomeThree,
    ProjectHomeThree,
    FooterHomeThree,
  },
  data() {
    return {
      step: 0,
      selectedPlan: null,
      searchQuery: "",
      address: null,
      addressResults: {
        features: [],
      },
      user: {
        name: null,
        email: null,
        phone: null,
      },
      polygonx: null,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
    map = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
      center: [-100.31847, 25.67507], // starting position [lng, lat]
      zoom: 13, // starting zoom
    });

    console.log(map)

    draw = new MapboxDraw({
      displayControlsDefault: false,
      // Select which mapbox-gl-draw control buttons to add to the map.
      controls: {
        polygon: true,
        trash: true
      },
      // Set mapbox-gl-draw to draw by default.
      // The user does not have to click the polygon control button first.
      defaultMode: 'draw_polygon',
      styles: polygonStyles,
    });
    map.addControl(draw);

    map.on('draw.create', this.updateAreaAndContinue);
    map.on('draw.delete', this.updateArea);
    map.on('draw.update', this.updateArea);
  },
  watch: {
    searchQuery: _.debounce(function searchQuery() {
      this.getAddress()
    }, 250),
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },

    updateArea() {
      const data = draw.getAll();
      this.polygonx = data;
    },

    updateAreaAndContinue() {
      const data = draw.getAll();
      this.polygonx = data;
      if (this.address) {
        this.step = 1;
      }
    },



    async getAddress() {
      let address = encodeURIComponent(this.searchQuery);
      fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?country=mx&proximity=ip&access_token=pk.eyJ1IjoibHNncnJkIiwiYSI6ImNsOHE4M3U2NDAwejgzbm9nM3M2YnZtMzkifQ.AodbZR_ka2PLb9-ezuYUHQ`)
        .then((response) => response.json())
        .then((data) => {
          this.addressResults = data;
          console.log(this.addressResults)
        });
    },

    async sentDataToFirebase() {
      try {
        const docRef = await addDoc(collection(db, "users"), {
          user: this.user,
          selectedPlan: {
            name: this.selectedPlan,
            price: this.selectedPlan === "estandar" ? "$3,500 MN" : "$4,500 MN",
          },
          polygon: JSON.stringify(this.polygonx),
          address: this.address,
          searchQuery: this.searchQuery,
          createdAt: new Date(),
          folio: Date.now(),
        });
        console.log("Document written with ID: ", docRef.id);

        const emailRef = await addDoc(collection(db, "mail"), {
          to: ["solicitudes@quieneseldueño.com", this.user.email],
          bcc: ["davidlzorrilla@gmail.com"],
          message: {
            subject: `Solicitud de búsqueda #${docRef.id}`,
            text: "This is the plaintext section of the email body.",
            html: "This is the <code>HTML</code> section of the email body.",
          },
          template: {
            name: "confirmation",
            data: {
              user: this.user,
              selectedPlan: {
                name: this.selectedPlan,
                price: this.selectedPlan === "estandar" ? "3,500 MN" : "4,500 MN",
              },
              polygon: JSON.stringify(this.polygonx),
              address: this.address,
              searchQuery: this.searchQuery,
              createdAt: new Date(),
              folio: Date.now(),
              id: docRef.id,
            }
          }
        });
        console.log("email with ID: ", emailRef.id);

      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },

    selectAddressAndCenterMap(address) {
      this.address = address;
      this.originalAddress = address;
      this.searchQuery = null;
      this.addressResults = {
        features: [],
      };

      map.flyTo({
       center: address.center,
       zoom: 18,
      });
    },

    completeForm() {
      this.step = 3;
      this.sentDataToFirebase()
    },

    resetForm() {
      this.step = 0;
      this.selectedPlan = null;
      this.searchQuery = null;
      this.address = null;
      this.addressResults = {
        features: [],
      };
      this.user = {
        name: null,
        email: null,
        phone: null,
      };
      this.polygonx = null;
    },
  },
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 100vh;
}

.content-area {
  background-image: url('../../assets/images/hero-bg-4.png');
  background-size: cover;
}

.form-step-1 {
  max-width: 400px;
}

.plan-card {
  border-radius: 20px;
  border: 2px solid #e28533;
  color: white;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.plan-card ul {
  list-style-type: circle;
  padding: 0 0 0 1rem ;
  margin: 0;
}

.plan-card ul li {
  margin-bottom: 1rem;
}

.plan-card .price {
  font-weight: 600;
  font-size: 29px;
}

.plan-card sup {
  font-size: 10px;
}

.plan-active {
  background-color: #e28533;
}

.plan-active:hover {
  background-color: #e98a36;
}

.step0 {
  max-width: 400px;
}

.result {
  border: 2px solid #e28533;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem 0;
  color: white;
  cursor: pointer;
}

.result:hover {
  border: 2px solid #e28533;
}

.result .selected {
  border: 2px solid #e28533;
  background-color: #e28533;
}

.info {
  padding: 1rem;
  margin: 1rem 0;
  color: white;
  border: 1px dashed #e28533;
  border-radius: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.btn:disabled {
  cursor: not-allowed;
}

.instructions {
  list-style: decimal;
}

.instructions li {
  margin-bottom: 1rem;
}

.ribbon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  text-align: center;
  transform: rotate(45deg) translateX(30%) translateY(0%);
  background-color: #e28533;
  color: white;
  font-size: 10px;
  padding: .2rem 2rem;
}

.recomended {
  transform: scale(1.05);
  border-width: 3px;
}
</style>