<template>
  <div class="bg">
    <div class="container">
      <div class="d-flex align-items-center justify-content-center" :class="{'d-none': pass == passInput}" v-show="pass != passInput">
        <div class="login-card m-5">
          <div class="text-white">password</div>
          <input type="password" v-model="passInput" class="form-control" id="">
        </div>
      </div>
      <div v-show="pass == passInput" class="row g-0">
        <div  class="col content-area">
          <img src="../assets/images/logo_quien.png" width="200" class="m-4">
          <div class="text-white p-4 d-flex pt-5 flex-column">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <h3 class="mb-3">Solicitudes</h3>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Fecha</th>
                          <th scope="col">Direccion</th>
                          <th scope="col">Nombre</th>
                          <th scope="col">Email</th>
                          <th scope="col">Tel</th>
                          <th scope="col">folio</th>
                        </tr>
                      </thead>
                      <tbody v-if="requests">
                        <tr v-for="(request, n) in requests" :key="n">
                          <td>{{ firebaseTimeStampToDate(request.createdAt) }}</td>
                          <td>
                            <router-link :to="{ name: 'Info', params: { id: request.id } }" target="_blank" rel="noopener noreferrer">
                              {{ request.address.place_name }}
                            </router-link>
                          </td>
                          <td>{{ request.user.name }}</td>
                          <td>{{ request.user.email }}</td>
                          <td>{{ request.user.phone }}</td>
                          <td>{{ request.folio }}</td>
      
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>

</template>

<script>
/* eslint-disable */
import mapboxgl from 'mapbox-gl';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, getDocs, orderBy } from "firebase/firestore";


mapboxgl.accessToken = 'pk.eyJ1IjoibHNncnJkIiwiYSI6ImNsOHE4M3U2NDAwejgzbm9nM3M2YnZtMzkifQ.AodbZR_ka2PLb9-ezuYUHQ';

const firebaseConfig = {
  apiKey: "AIzaSyDgXFzOgSwWmulpQLjTMs0mFe9QEqs3Iz4",
  authDomain: "quieneseld.firebaseapp.com",
  projectId: "quieneseld",
  storageBucket: "quieneseld.appspot.com",
  messagingSenderId: "933880676258",
  appId: "1:933880676258:web:0678886c731c75dec4bcfb",
  measurementId: "G-QE6WSE844S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

let map;
let draw;

export default {
  components: {
  },
  data() {
    return {
      requests: [],
      pass: 'quieneseldueno',
      passInput: '',
    };
  },
  mounted() {

    this.getFirebaseData()

  },
  watch: {

  },
  methods: {
    firebaseTimeStampToDate(timestamp) {
      let date = new Date(timestamp.seconds * 1000);
      let DDMMMYYYY =  date.toLocaleDateString('es-MX', { year: 'numeric', month: 'short', day: 'numeric' });

      return DDMMMYYYY;
    },

    async getFirebaseData() {
      const querySnapshot = await getDocs(collection(db, "users"));
      querySnapshot.forEach((doc) => {
        this.requests.push({
          id: doc.id,
          ...doc.data()
        })
      });
      this.requests.sort((a, b) => {
        return  b.createdAt.seconds - a.createdAt.seconds;
      });
    },
  },
};

</script>

<style scoped>

.bg {
  min-height: 100vh;
  background-color: #1d2a4c;
}

</style>