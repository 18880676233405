<template>
<div class="row g-0">
  <div v-if="request != null" class="col-12 col-md-6 content-area">
    <img src="../assets/images/logo_quien.png" width="200" class="m-4">
    <div class="text-white p-4 d-flex pt-5 flex-column">
      <label for="">Dirección:</label>
      <div class="text-white mb-5 h1 ">{{ request.address.place_name }}</div>

      <div class="d-flex flex-column mb-2">
        <label for="">Nombre:</label>
        <div class="h2">
          {{ request.user.name }}
        </div> 
      </div>
      <div class="d-flex flex-column mb-2">
        <label for="">Email:</label>
        <div class="h2">
          {{ request.user.email }}
        </div> 
      </div>
      <div class="d-flex flex-column mb-2">
        <label for="">Tel:</label>
        <div class="h2">
          {{ request.user.phone }}
        </div>
      </div>
      <div class="d-flex flex-column mt-3 mb-2">
        <label for="">Servicio:</label>
        <div class="h2">
          {{ request.selectedPlan.name }}
        </div>
      </div>
      <div class="d-flex flex-column mb-2">
        <label for="">Fecha:</label>
        <div class="h4">
          {{ firebaseTimeStampToDate(request.createdAt) }}
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6">
    <div id="map">
    </div>
  </div>
</div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDoc, doc } from "firebase/firestore";


mapboxgl.accessToken = 'pk.eyJ1IjoibHNncnJkIiwiYSI6ImNsOHE4M3U2NDAwejgzbm9nM3M2YnZtMzkifQ.AodbZR_ka2PLb9-ezuYUHQ';

const firebaseConfig = {
  apiKey: "AIzaSyDgXFzOgSwWmulpQLjTMs0mFe9QEqs3Iz4",
  authDomain: "quieneseld.firebaseapp.com",
  projectId: "quieneseld",
  storageBucket: "quieneseld.appspot.com",
  messagingSenderId: "933880676258",
  appId: "1:933880676258:web:0678886c731c75dec4bcfb",
  measurementId: "G-QE6WSE844S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

let map;
let draw;

export default {
  components: {
  },
  data() {
    return {
      request: null
    };
  },
  mounted() {

    map = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/mapbox/satellite-streets-v12', // style URL
      center: [-100.31847, 25.67507], // starting position [lng, lat]
      zoom: 13, // starting zoom
    });

    this.getFirebaseData()

  },
  watch: {

  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".back-to-top");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.style.display = "block";
      } else {
        result.style.display = "none";
      }
    },

    updateArea() {
      const data = draw.getAll();
      this.polygonx = data;
      console.log(this.polygonx)
    },

    updateAreaAndContinue() {
      const data = draw.getAll();
      this.polygonx = data;
      console.log(this.polygonx)
      this.step = 1;
    },

    firebaseTimeStampToDate(timestamp) {
      let date = new Date(timestamp.seconds * 1000);
      let DDMMMYYYY =  date.toLocaleDateString('es-MX', { year: 'numeric', month: 'short', day: 'numeric' });

      return DDMMMYYYY;
    },

    async getFirebaseData() {
      const docRef = doc(db, "users", this.$route.params.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        this.request = docSnap.data();
        console.log("Document data:", docSnap.data());


        this.centerMap(this.request.address)

      map.addSource('maine', {
        type: 'geojson',
        data: JSON.parse(this.request.polygon)
      });

      // Add a new layer to visualize the polygon.
      map.addLayer({
        'id': 'maine',
        'type': 'fill',
        'source': 'maine', // reference the data source
        'layout': {},
        'paint': {
          'fill-color': '#0080ff', // blue color fill
          'fill-opacity': 0.5
        }
      });
      // Add a black outline around the polygon.
      map.addLayer({
        'id': 'outline',
        'type': 'line',
        'source': 'maine',
        'layout': {},
        'paint': {
          'line-color': '#000',
          'line-width': 3
        }
      });

      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }

    },

    centerMap(address) {
      map.flyTo({
       center: address.center,
       zoom: 18,
      });
    },
  },
};

</script>

<style scoped>
#map {
  width: 100%;
  height: 100vh;
}

.content-area {
  background-image: url('../assets/images/hero-bg-4.png');
  background-size: cover;
}

.form-step-1 {
  max-width: 400px;
}

.plan-card {
  border-radius: 20px;
  border: 2px solid #e28533;
  color: white;
  cursor: pointer;
}

.plan-card ul {
  list-style-type: circle;
  padding: 0 0 0 1rem ;
  margin: 0;
}

.plan-card ul li {
  margin-bottom: 1rem;
}

.plan-card .price {
  font-weight: 600;
  font-size: 29px;
}

.plan-card sup {
  font-size: 10px;
}

.plan-active {
  background-color: #e28533;
}

.plan-active:hover {
  background-color: #e98a36;
}

.step0 {
  max-width: 400px;
}

.result {
  border: 2px solid #e28533;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem 0;
  color: white;
  cursor: pointer;
}

.result:hover {
  border: 2px solid #e28533;
}

.result .selected {
  border: 2px solid #e28533;
  background-color: #e28533;
}

.info {
  border: 2px dashed #e28533;
  border-radius: 10px;
  padding: 1rem;
  margin: 1rem 0;
  color: white;
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.btn:disabled {
  cursor: not-allowed;
}
</style>