import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeThree from '../views/HomeThree.vue'
import InfoPage from '../views/InfoPage.vue'
import RequestsPage from '../views/RequestsTable.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeThree
  },
  {
    path: '/requests',
    name: 'Requests',
    component: RequestsPage
  },
  {
    path: '/:id',
    name: 'Info',
    component: InfoPage
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // stop stop cache
  scrollBehavior() {
      document.getElementById('app').scrollIntoView();
  }
})

export default router
