<template>
      <!--====== APPIE FOOTER PART START ======-->
    
    <section class="appie-footer-area appie-footer-3-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="footer-about-widget footer-about-widget-3">
                        <div class="logo">
                            <a href="#"><img src="@/assets/images/logo_quien2.png" alt=""></a>
                        </div>
                        <a href="#">Terminos y condiciones <i class="fal fa-arrow-right"></i></a>
                       
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    
                </div>
                <div class="col-lg-3 col-md-6">
                    
                </div>
                <div class="col-lg-3 col-md-6">
                    <div class="footer-widget-info">
                        <h4 class="title">Contacto</h4>
                        <ul>
                            <li><a href="#"><i class="fal fa-envelope"></i>soporte@quieneseldueño.com</a></li>
                            <li><a href="#"><i class="fal fa-phone"></i>+ (52)81 2205 2480</a></li>
                            <li><a href="#"><i class="fal fa-map-marker-alt"></i>Monterrey, Nuevo León</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-copyright d-flex align-items-center justify-content-between pt-35">
                       
                        <div class="copyright-text">
                            <p>Copyright © 2023 Quien es el dueño.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <!--====== APPIE FOOTER PART ENDS ======-->
</template>

<script>
export default {

}
</script>

<style>

</style>